import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import gameReducer from "./slices/gameSlice";
import navigationReducer from "./slices/navigationSlice.js";

const persistConfig = {
    key: "root",
    storage,
};

const reducers = combineReducers({
    game: gameReducer,
    navigation: navigationReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }),
});

export const persistor = persistStore(store);