import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const RouteCheck = () => {
    const navigate = useNavigate();
    const navigation = useSelector(state => state.navigation);

    useEffect(() => {
        if (navigation && navigation.route && navigation.route !== "") {
            navigate(navigation.route);
        }
    }, [navigation]);
}

export default RouteCheck;