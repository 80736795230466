import {Box, Button, Stack, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import GameCard from "../../components/home/featuredGames/gameCard";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";

import bg from "../../assets/images/featured_games_section_bg.png";
import game8 from "../../assets/images/games/game_8.webp";
import game9 from "../../assets/images/games/game_9.webp";
import game4 from "../../assets/images/games/game_4.webp";

const mainContainer = {
    height: 'fit-content',
    maxHeight: {xs: 'auto', md: '800px'},
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '3rem',
    paddingBottom: '5rem',
    overflow: 'hidden'
}

const cardsData = [
    {
        id: 9,
        condition: "Coming Soon",
        duration: 1,
        title: "Block Wars",
        description: "This game is perfect for kids aged 8 and up, and it’s all about getting the most blocks in your color and winning the round. Block Wars is a fun way to practice ratio and probability skills.",
        img: game8,
        age: "8-12",
        type: "Strategy / Action",
        subject: "Ratio, Probability",
        technology: ["3D", "Mobile"]
    },

    {
        condition: "Coming Soon",
        duration: 1.1,
        id: 10,
        title: "Color Run",
        description: "Get ready for an exciting new game for kids aged 6 and up! In this game, you’ll have to use your strategy and timing skills to guide multiple characters along a rail without colliding with each other. Enjoy while learning with Paint The Path!",
        img: game9,
        age: "6-10",
        type: "Action / Strategy",
        subject: "Strategy, Timing skills",
        technology: ["2D", "Mobile"]
    },

    {
        condition: "Coming Soon",
        duration: 1.2,
        id: 6,
        title: "Candy Match 3D",
        description: "Candy Match 3D is a puzzle game that is designed for children who are 3 years old and above, and its objective is to match groups of tiles by attribute in order to clear the board.",
        img: game4,
        age: "4-8",
        type: "Puzzle",
        subject: "Pattern recognition, Logic",
        technology: ["3D", "Mobile"]
    },
]

const buttonStyle = {
    border: '2px solid #2b3c4e',
    backgroundColor: 'white',
    color: '#2b3c4e',
    paddingY: '0.5rem',
    fontWeight: '600',
    borderRadius: "0.25rem",
    transition: '0.25s',
    '&:hover': {
        backgroundColor: '#2b3c4e',
        color: 'white'
    },
}

const FeaturedGames = () => {
    const navigate = useNavigate();

    return(
        <Box sx={mainContainer}>
            <motion.div
                initial={{x: -150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center'}}>
                    <Box component='span' sx={{color: yellow[600]}}>
                        The Arcade
                    </Box>
                    <Box component='span' sx={{color: yellow[800]}}>
                        {" "}Academy
                    </Box>
                    <Box component='span' sx={{color: '#062e42'}}>
                        {" "}Featured Games
                    </Box>
                </Typography>
            </motion.div>
            <motion.div
                initial={{x: 150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography vaiant='body2' sx={{
                    textAlign: 'center',
                    width: {xs: '90%', sm: '550px', md: '850px', lg: '1000px'},
                    marginY: {xs: '1rem', sm: '2rem', md: '4rem'},
                    marginX: {xs: 'auto', md: 0}
                }}>
                    <Box component='span' sx={{color: '#062e42', fontWeight: '600'}}>The Arcade Academy,</Box> designed
                    in consultation with
                    cutting edge scientists, brings the joy of discovery of students of all abilities. Some games
                    specifically
                    designed for children on the autism spectrum or with other learning differences.
                </Typography>
            </motion.div>

            <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', rowGap: '2rem', position: 'relative', width: {xs: '100%', md: 'fit-content'}}}>
                <Box component='img' src={bg} alt={'Background'} sx={{position: 'absolute', height: {xs: '12.5%', sm: '15%', md: '35%'}, width: '100%', bottom: 0}} />
                <Stack direction={{xs: 'column', md: 'row'}}
                       sx={{columnGap: {md: '1rem', lg: '3rem'}, rowGap: '3rem'}}>
                    {
                        cardsData.map((cardData, index) => (
                            <GameCard cardData={cardData} key={index}/>
                        ))
                    }
                </Stack>
                <Stack direction={{xs: 'column', sm: 'row'}}
                       sx={{alignItems: 'center', justifyContent: 'center', columnGap: '1rem', rowGap: '1rem', marginBottom: '2rem'}}>
                    <Button sx={buttonStyle} onClick={()=> navigate("/games")}>More Projects</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default FeaturedGames;