import {Box, Grid2, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import GameCard from "../../components/games/gameCard";
import vrGamesData from "../../data/vrGamesData";

const mainContainer = {
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '0rem',
    paddingBottom: '7rem'
}

const cardsData = [
    {
        title: "Soccer Shoot",
        platform: [
            "AR", "VR"
        ],
        condition: "Coming Soon",
        description: "VR game exploring biology concepts with interactive models"
    },

    {
        title: "Heart Anatomy",
        platform: [
            "AR", "VR"
        ],
        condition: "Coming Soon",
        description: "VR game exploring biology concepts with interactive models"
    },

    {
        title: "Music Hall",
        platform: [
            "AR", "VR"
        ],
        condition: "Coming Soon",
        description: "VR game exploring biology concepts with interactive models"
    },
]


const UpcomingVRARGames = () => {
    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center'}}>
                <Box component='span' sx={{color: '#062e42'}}>
                    Upcoming
                </Box>
                <Box component='span' sx={{color: yellow[600]}}>
                    {" "}AR and
                </Box>
                <Box component='span' sx={{color: yellow[800]}}>
                    {" "}VR Games
                </Box>
            </Typography>
            <Typography vaiant='body2' sx={{textAlign: 'center', width: {xs: '90vw', md: '850px'}, marginY: '2rem'}}>
                We are proud to announce that all our developers, scientist and educators are working on the next VR (Virtual Reality)
                and AR (Augmented Reality) games. Stay tuned!
            </Typography>

            <Grid2 container spacing={5} sx={{width: {xs: '275px', sm: '580px', md: '860px', lg: '1180px'}}}>
                {vrGamesData.map((cardData, index) =>(
                    <GameCard cardData={cardData} key={index} />
                ))}
            </Grid2>
        </Box>
    )
}

export default UpcomingVRARGames;