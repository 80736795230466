import {Box, Stack, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import header_bg from "../assets/images/blog_header_bg.webp";

const mainContainer = {
    height: 'calc(100vh - 14rem)',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    color: 'white',
    background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),url(${header_bg}) center/cover no-repeat`
}

const Blogs = () => {
    window.scrollTo(0, 0);

    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center'}}>
                <Box component='span' sx={{color: yellow[600]}}>
                    The
                </Box>
                <Box component='span' sx={{color: yellow[800]}}>
                    {" "}Arcade Academy
                </Box>
                <Box component='span' sx={{color: '#0082c1'}}>
                    {" "}Blogs
                </Box>
            </Typography>
            <Typography vaiant='body2' sx={{fontSize: '0.95rem', paddingX: '1rem', textAlign: 'center', width: {xs: '95%', sm: '550px', md: '850px', lg: '1150px'}}}>
                Welcome to Arcade Academy, your go-to source for everything arcade! Explore the latest tips, game reviews, and retro insights that keep the spirit of classic gaming alive. Level up your knowledge with us!
            </Typography>
            <Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: 'center', justifyContent: 'center', flex: 1, paddingY: '1rem'}}>
                <Typography variant='h5' sx={{textAlign: 'center'}}>
                    Oops you'll have a lot to read very soon.{" "}
                </Typography>
                {/*<Box component='img' src={oops} alt={"Oops"} sx={{height: '60px', width: '60px'}}/>*/}
            </Stack>
        </Box>
    )
}

export default Blogs;