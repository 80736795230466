import Header from "../sections/game/header";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import GameDetails from "../sections/game/gameDetails";
import {useEffect} from "react";

const Game = () => {
    const navigate = useNavigate();
    const game = useSelector(state => state.game);

    useEffect(() => {
        if (!game || !game.id) {
            navigate("/games")
        }

        window.scrollTo(0, 0);
    }, [game]);

    return(
        <>
            <Header game={game}/>
            <GameDetails game={game}/>
        </>
    )
}

export default Game;