import {blue} from "@mui/material/colors";
import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import logo from "../assets/images/header_logo.png";

const mainContainer = {
    minHeight: 'calc(100vh - 9rem)',
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '1rem',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    color: '#052e42',
    background: 'radial-gradient(circle, rgba(82,166,254,1) 0%, rgba(44,68,92,1) 100%)'
}

const containerStack = {
    height: '100%',
    width: {xs: '100%', sm: '550px'},
    alignItems: 'center',
    justifyContent: 'center'
}


const signUp = {
    backgroundColor: blue[500],
    color: 'white',
    textTransform: 'capitalize',
    padding: '0.5rem 1rem',
    width: '75%',
    height: '51px'
}

const SignUp = () => {
    const navigate = useNavigate();

    return (
        <Box sx={mainContainer}>
            <Stack sx={containerStack}>
                <Stack sx={{rowGap: '1rem', width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white', padding: '1rem 1.5rem', borderRadius: '0.25rem', boxShadow: '0 0 8px rgba(0,0,0,0.5)'}}>
                    <Box component='img' src={logo} alt={'The Arcade Academy'} sx={{height: '165px', width: '150px'}} />
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <Typography variant='h6' sx={{}}>Sign Up</Typography>
                        <Typography variant='h6' sx={{cursor: 'pointer'}} onClick={()=> navigate("/sign_in")}>Already have an account</Typography>
                    </Stack>
                    <TextField fullWidth variant='outlined' placeholder={"Name"}></TextField>
                    <TextField fullWidth variant='outlined' placeholder={"Surname"}></TextField>
                    <TextField fullWidth variant='outlined' placeholder={"Email Address"}></TextField>
                    <TextField fullWidth variant='outlined' placeholder={"Password"}></TextField>
                    <TextField fullWidth variant='outlined' placeholder={"Username"}></TextField>
                    <Button sx={signUp} variant='contained'>
                        Sign Up
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default SignUp;