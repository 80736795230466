import {Box, Stack, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import what1 from "../../assets/images/what_pic_1.png";
import what2 from "../../assets/images/what_pic_2.png";
import what3 from "../../assets/images/what_pic_3.png";
import what4 from "../../assets/images/what_pic_4.png";
import {motion} from "framer-motion";

const mainContainer = {
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '1rem',
    paddingBottom: '5rem',
    overflow: 'hidden'
}

const WhatIsArcadeAcademy = () => {
    return(
        <Box sx={mainContainer}>
            <motion.div
                initial={{x: -150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center'}}>
                    <Box component='span' sx={{color: '#062e42'}}>
                        What is
                    </Box>
                    <Box component='span' sx={{color: yellow[600]}}>
                        {" "}The Arcade
                    </Box>
                    <Box component='span' sx={{color: yellow[800]}}>
                        {" "}Academy?
                    </Box>
                </Typography>
            </motion.div>
            <motion.div
                initial={{x: 150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography vaiant='body2' sx={{
                    textAlign: 'center',
                    width: {xs: '100%%', sm: '550px', md: '850px', lg: '1000px'},
                    marginY: {xs: '1rem', sm: '2rem'},
                }}>
                    Science based education games built for children, teachers, parents and scientists of the future.
                    Alter
                    learning's game collection designed in collaboration with cutting edge scientists, bring the joy of
                    discovery
                    to students of all abilities. These games can supplement classroom learning or help reinforce
                    children and
                    teens knowledge and critical thinking skills. Working directly with game developers, renowned
                    international
                    research scientists in many fields have infused the storylines with the wonder and passion that
                    inspire them.
                </Typography>
            </motion.div>
            <motion.div
                initial={{x: -150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography vaiant='body2' sx={{
                    textAlign: 'center',
                    width: {xs: '100%', sm: '550px', md: '850px', lg: '1000px'},
                    marginBottom: '2rem'
                }}>
                    We innovate learning using XR (Extended Reality). XR is a combo of AR ( Augmented Reality ) and VR (
                    Virtual Reality ).
                    We are a secure, cloud based platform supporting students and educators in both online and
                    face-to-face classes.
                </Typography>
            </motion.div>
            <Stack direction={{xs: 'column', md: 'row'}} sx={{
                alignItems: {xs: 'center', sm: 'start'},
                justifyContent: 'center',
                columnGap: {md: '2rem', lg: '12rem'},
                rowGap: '2rem'
            }}>
                <motion.div
                    initial={{x: -150, opacity: 0}} // move the text off-screen to the left
                    whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                    transition={{duration: 1, ease: 'easeOut'}} // animation settings
                >
                    <Stack sx={{alignItems: 'center', width: {xs: '100%', sm: '400px'}, rowGap: '5rem'}}>
                        <Box component='img' src={what1} alt={"Alter1"} sx={{
                            boxShadow: '0 0 16px 6px rgba(0, 0, 0, 0.25)',
                            border: '2px solid #2b3c4e',
                            height: {xs: 'auto', sm: '400px'},
                            width: {xs: '85%', sm: '400px'}
                        }}/>
                        <Box component='img' src={what3} alt={"Alter3"} sx={{
                            boxShadow: '0 0 16px 6px rgba(0, 0, 0, 0.25)',
                            border: '2px solid #2b3c4e',
                            height: {xs: 'auto', sm: '350px'},
                            width: {xs: '85%', sm: '350px'}
                        }}/>
                        <Stack>
                            <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center', fontSize: '2rem'}}>Developers
                                and Partners</Typography>
                            <Typography variant='body2' sx={{textAlign: 'center'}}>
                                We have partnered up with educational game developers that specializes in VR and AR
                                formats to
                                create tailored content for K-12 students. Are you a game developer or studio interested
                                in creating
                                educational games and need a platform to reach your audience? We would love to hear from
                                you. Please, contact us.
                            </Typography>
                        </Stack>
                    </Stack>
                </motion.div>
                <motion.div
                    initial={{x: 150, opacity: 0}} // move the text off-screen to the left
                    whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                    transition={{duration: 1, ease: 'easeOut'}} // animation settings
                >
                    <Stack sx={{alignItems: 'center', width: {xs: '100%', sm: '400px'}, rowGap: '5rem'}}>
                        <Box component='img' src={what2} alt={"Alter2"} sx={{
                            boxShadow: '0 0 16px 6px rgba(0, 0, 0, 0.25)',
                            border: '2px solid #2b3c4e',
                            height: {xs: 'auto', sm: '400px'},
                            width: {xs: '85%', sm: '400px'}
                        }}/>
                        <Stack>
                            <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center', fontSize: '2rem'}}>Students
                                and Families</Typography>
                            <Typography variant='body2' sx={{textAlign: 'center'}}>
                                We have partnered up with educational game developers that specializes in VR and AR
                                formats to
                                create tailored content for K-12 students. Are you a game developer or studio interested
                                in creating
                                educational games and need a platform to reach your audience? We would love to hear from
                                you. Please, contact us.
                            </Typography>
                        </Stack>
                        <Box component='img' src={what4} alt={"Alter4"} sx={{
                            boxShadow: '0 0 16px 6px rgba(0, 0, 0, 0.25)',
                            border: '2px solid #2b3c4e',
                            height: {xs: 'auto', sm: '350px'},
                            width: {xs: '85%', sm: '350px'}
                        }}/>
                    </Stack>
                </motion.div>
            </Stack>
        </Box>
    )
}

export default WhatIsArcadeAcademy;