import game12 from "../assets/images/games/game_12.webp";
import game13 from "../assets/images/games/game_13.webp";
import game14 from "../assets/images/games/game_14.webp";
import game2 from "../assets/images/games/game_2.webp";

const vrGamesData = [
    {
        id: 3,
        title: "Reading World (VR)",
        description: "Math World VR combines fun games with simple math to exercise the brain!",
        img: game12,
        age: "7-12",
        type: "Educational (VR)",
        subject: "Math, Cognitive skills",
        technology: ["VR", "3D"]
    },

    {
        id: 7,
        title: "Futuclass (VR)",
        description: "Co-created with award-winning science teachers, the learning value of each VR modules has been proven and tested with real students in classroom and at home.",
        img: game13,
        age: "10-12",
        type: "Educational (VR)",
        subject: "Science, Chemistry",
        technology: ["VR", "3D"]
    },

    {
        id: 13,
        title: "Vacation Simulator VR",
        description: "From the creators of Job Simulator, Vacation Simulator offers another entertaining and educational VR experience. Players can explore various vacation destinations, such as the beach, the mountains, or a forest, while completing tasks and interacting with the environment.",
        img: game14,
        age: "10-12",
        type: "Simulation (VR)",
        subject: "Exploration, Interaction",
        technology: ["VR", "3D"]
    },

    {
        id: 14,
        title: "Human Anatomy",
        description: "The Human Anatomy game focuses on exploration of the human body by demonstrating a variety of movements such as crawling, running, and walking. Lesson plans dive deep into atoms, cells, tissues, organs and body structures as the player interacts with the different parts of the human anatomy.",
        img: game2,
        age: "8-12",
        type: "Educational / Simulation",
        subject: "Biology, Human Anatomy",
        technology: ["3D", "VR"]
    }
];

export default vrGamesData;