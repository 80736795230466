import {createTheme} from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: [
            "Poppins",
        ].join(',')
    }
})

export default theme;