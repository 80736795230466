import {Box, Button, Checkbox, Stack, TextField, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import logo from "../assets/images/header_logo.png";
import googleIcon from "../assets/icons/google_icon.svg";
import {useNavigate} from "react-router-dom";

const mainContainer = {
    minHeight: 'calc(100vh - 9rem)',
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '1rem',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    color: '#052e42',
    background: 'radial-gradient(circle, rgba(82,166,254,1) 0%, rgba(44,68,92,1) 100%)'
}

const containerStack = {
    height: '100%',
    width: {xs: '100%', sm: '550px'},
    alignItems: 'center',
    justifyContent: 'center'
}

const loginButton = {
    backgroundColor: blue[500],
    color: 'white',
    textTransform: 'capitalize',
    padding: '0.5rem 1rem',
    width: '75%',
    height: '51px'
}

const googleLoginButton = {
    backgroundColor: grey[200],
    color: 'black',
    textTransform: 'capitalize',
    padding: '0.5rem 1rem',
    width: '75%'
}

const SignIn = () => {
    const navigate = useNavigate();

    return(
        <Box sx={mainContainer}>
            <Stack sx={containerStack}>
                <Stack sx={{rowGap: '1rem', width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white', padding: '1rem 1.5rem', borderRadius: '0.25rem', boxShadow: '0 0 8px rgba(0,0,0,0.5)'}}>
                    <Box component='img' src={logo} alt={'The Arcade Academy'} sx={{height: '165px', width: '150px'}} />
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <Typography variant='h6' sx={{}}>Sign in</Typography>
                        <Typography variant='h6' sx={{cursor: 'pointer'}} onClick={()=> navigate('/sign_up')}>Create an Account</Typography>
                    </Stack>
                    <TextField fullWidth variant='outlined' placeholder={"Email Address"}></TextField>
                    <TextField fullWidth variant='outlined' placeholder={"Password"}></TextField>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <Stack direction='row' sx={{alignItems: 'center'}}>
                            <Checkbox />
                            <Typography variant='body2'>Remember Me</Typography>
                        </Stack>
                        <Typography variant='body2' sx={{cursor: "pointer"}}>Forgot Password?</Typography>
                    </Stack>
                    <Button sx={loginButton} variant='contained'>
                        Login
                    </Button>
                    <Button startIcon={<Box component='img' src={googleIcon} alt='Google' sx={{height: '35px', width: '35px'}}/>} sx={googleLoginButton} variant='contained'>
                        Login with Google
                    </Button>
                    <Typography variant='body2'>
                        <Box component='span' sx={{fontWeight: '600'}}>Terms and Conditions</Box>
                        {" "}and{" "}
                        <Box component='span' sx={{fontWeight: '600'}}>Privacy Policy</Box>
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}

export default SignIn