import {createSlice} from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        route: "/"
    },
    reducers: {
        setNavigation: (state, action) => {
            state.route = action.payload
        }
    }
})

export const {setNavigation} = navigationSlice.actions;

export default navigationSlice.reducer