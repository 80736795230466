import {Box, Grid2, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import GameCard from "../../components/games/gameCard";
import gamesData from "../../data/gamesData";

const mainContainer = {
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '5rem',
    paddingBottom: '3rem'
}

// const cardsData = [
//     {
//         id: 1,
//         title: "Soccer Shoot",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 2,
//         title: "Heart Anatomy",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 3,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 4,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 5,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 6,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 7,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 8,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     },
//
//     {
//         id: 9,
//         title: "Music Hall",
//         platform: [
//             "AR", "VR"
//         ],
//         condition: "Coming Soon",
//         description: "VR game exploring biology concepts with interactive models"
//     }
// ]

const UpcomingBestGames = () => {
    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{fontWeight: '600', textAlign: 'center'}}>
                <Box component='span' sx={{color: '#062e42'}}>
                    Upcoming
                </Box>
                <Box component='span' sx={{color: yellow[600]}}>
                    {" "}Games
                </Box>
                <Box component='span' sx={{color: yellow[800]}}>
                    {" "}for Kids!
                </Box>
            </Typography>
            <Typography vaiant='body2' sx={{textAlign: 'center', width: {xs: '90vw', md: '850px'}, marginY: '2rem'}}>
                <Box component='span' sx={{color: '#062e42', fontWeight: '600'}}>The Arcade Academy,</Box> designed in consultation with
                cutting edge scientists, brings the joy of discovery of students of all abilities. Some games specifically
                designed for children on the autism spectrum or with other learning differences.
            </Typography>

            <Grid2 container spacing={5} sx={{width: {xs: '275px', sm: '580px', md: '860px', lg: '1180px'}}}>
                {gamesData.map((cardData, index) =>(
                    <GameCard cardData={cardData} key={index} />
                ))}
            </Grid2>
        </Box>
    )
}

export default UpcomingBestGames;