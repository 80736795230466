import {Box, Grid2, Stack, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setGameInfo} from "../../store/slices/gameSlice";

const cardStyle = {
    cursor: 'pointer',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'start',
    border: '2px solid #2b3c4e',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    transition: '0.25s',
    '&:hover': {
        scale: '1.025',
        boxShadow: '0 0 32px 0 rgba(0,0,0,0.25)'
    }
}

const GameCard = ({cardData}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleMoreGameDetails = (id) => {
        dispatch(setGameInfo(cardData));
        navigate(`/game/${id}`)
    }

    return(
        <Grid2 onClick={()=> handleMoreGameDetails(cardData.id)} size={{xs: 12, sm: 6, md: 4, lg: 3}} sx={cardStyle}>
            <Stack sx={{height: '250px', alignItems: 'end', justifyContent: 'top',
                backgroundImage: `url(${cardData.img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            >
                <Stack direction='row'
                       sx={{alignItems: 'center', columnGap: '0.5rem', margin: '0.75rem 1rem',}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', padding: '0.125rem 0.5rem',backgroundColor: yellow[700], border: '2px solid #2b3c4e', borderTopLeftRadius: '50%', borderBottomRightRadius: '50%'}}>
                        {cardData.technology[0]}
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: '0.75rem',padding: '0.125rem 0.5rem',backgroundColor: yellow[700], border: '2px solid #2b3c4e', borderTopLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
                        Coming Soon
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: '0.75rem',padding: '0.125rem 0.5rem',backgroundColor: yellow[700], border: '2px solid #2b3c4e', borderTopLeftRadius: '50%', borderBottomRightRadius: '50%'}}>
                        {cardData.technology[1]}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{backgroundColor: yellow[700], paddingY: '1rem', paddingX: '0.5rem', flex: 1}}>
                <Typography variant='h6' sx={{fontWeight: '600'}}>{cardData.title}</Typography>
                <Typography variant='body2' sx={{fontSize: '0.7rem'}}>{cardData.description}</Typography>
            </Box>
        </Grid2>
    )
}

export default GameCard;