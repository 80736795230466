import {Box} from "@mui/material";
import MobileApps from "../sections/downloads/mobileApps";
import DesktopApps from "../sections/downloads/desktopApps";
import VRApps from "../sections/downloads/VRApps";

const Downloads = () => {
    window.scrollTo(0, 0);

    return(
        <Box sx={{background: 'linear-gradient(90deg, rgba(44,68,92,1) 0%, rgba(82,166,254,1) 100%)'}}>
            <MobileApps />
            <DesktopApps />
            <VRApps />
        </Box>
    )
}

export default Downloads;