import {Box, Stack, Typography} from "@mui/material";
import sectionBG from "../../assets/images/sectionBG.png";
import accessibility from "../../assets/images/accessibility.png";
import friendly from "../../assets/images/friendly.png";
import interactive from "../../assets/images/interactive.png";
import {motion} from "framer-motion";

const mainContainer = {
    height: 'fit-content',
    maxHeight: {xs: 'auto', lg: '800px'},
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${sectionBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    overflow: 'hidden'
}

const gridImage = {
    height: {xs: '250px', md: '225px', lg: '275px'},
    width: {xs: '250px', md: '225px', lg: '275px'}
}

const ArcadeAcademyHighlights = () => {
    return(
        <Box sx={mainContainer}>
            <motion.div
                style={{marginTop: '3rem'}}
                initial={{x: -150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography variant='h4' sx={{textAlign: 'center'}}>The Arcade Academy</Typography>
            </motion.div>
            <motion.div
                initial={{x: 150, opacity: 0}} // move the text off-screen to the left
                whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                transition={{duration: 1, ease: 'easeOut'}} // animation settings
            >
                <Typography vaiant='body2' sx={{textAlign: 'center'}}>
                    A unified learning games platform for engaging, educating and entertaining the modern learner.
                </Typography>
            </motion.div>
            <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', marginY: '3rem'}}>

                <Stack direction={{xs: 'column', md: 'row'}} sx={{columnGap: {md: '2rem', lg: '4rem'}, rowGap: '2rem'}}>
                    <motion.div
                        initial={{y: -150, opacity: 0}} // move the text off-screen to the left
                        whileInView={{y: 0, opacity: 1}} // move the text back to its original position
                        transition={{duration: 1, ease: 'easeOut'}} // animation settings
                    >
                        <Stack
                            sx={{width: {xs: '250px', md: '225px', lg: '275px'}, textAlign: 'center', rowGap: '1rem'}}>
                            <motion.div
                                whileHover={{scale: 1.1}}
                                transition={{type: "spring", stiffness: 400, damping: 17}}
                            >
                                <Box component='img' src={interactive} alt={'Interactive Learning'} sx={gridImage}/>
                            </motion.div>
                            <Typography variant='h6' sx={{fontSize: '1.15rem', fontWeight: '600'}}>Interactive
                                Learning</Typography>
                            <Typography variant='body2'>
                                VR, 3D and 2D game modes allow immersive learning experience
                            </Typography>
                        </Stack>
                    </motion.div>

                    <Box sx={{
                        height: {xs: '1px', md: '300px'},
                        minWidth: {xs: '100%', md: '3px'},
                        background: 'linear-gradient(180deg, rgba(183,241,61,1) 0%, rgba(61,163,241,1) 100%)',
                        borderRadius: '1rem'
                    }}/>

                    <motion.div
                        initial={{y: -150, opacity: 0}} // move the text off-screen to the left
                        whileInView={{y: 0, opacity: 1}} // move the text back to its original position
                        transition={{duration: 1.2, ease: 'easeOut'}} // animation settings
                    >
                        <Stack
                            sx={{width: {xs: '250px', md: '225px', lg: '275px'}, textAlign: 'center', rowGap: '1rem'}}>
                            <motion.div
                                whileHover={{scale: 1.1}}
                                transition={{type: "spring", stiffness: 400, damping: 17}}
                            >
                                <Box component='img' src={accessibility} alt={'Accessibility'} sx={gridImage}/>
                            </motion.div>
                            <Typography variant='h6'
                                        sx={{fontSize: '1.15rem', fontWeight: '600'}}>Accessibility</Typography>
                            <Typography variant='body2'>
                                Mobile, PC and web access ensure seamless learning across platforms
                            </Typography>
                        </Stack>
                    </motion.div>

                    <Box sx={{
                        height: {xs: '1px', md: '300px'},
                        minWidth: {xs: '100%', md: '3px'},
                        background: 'linear-gradient(180deg, rgba(241,191,61,1) 0%, rgba(183,241,61,1) 100%)',
                        borderRadius: '1rem'
                    }}/>

                    <motion.div
                        initial={{y: -150, opacity: 0}} // move the text off-screen to the left
                        whileInView={{y: 0, opacity: 1}} // move the text back to its original position
                        transition={{duration: 1.4, ease: 'easeOut'}} // animation settings
                    >
                        <Stack
                            sx={{width: {xs: '250px', md: '225px', lg: '275px'}, textAlign: 'center', rowGap: '1rem'}}>
                            <motion.div
                                whileHover={{scale: 1.1}}
                                transition={{type: "spring", stiffness: 400, damping: 17}}
                            >
                                <Box component='img' src={friendly} alt={'Friendly'} sx={gridImage}/>
                            </motion.div>
                            <Typography variant='h6' sx={{fontSize: '1.15rem', fontWeight: '600'}}>Kid-Friendly
                                Environment</Typography>
                            <Typography variant='body2'>
                                All games are designed with child safety and privacy in mind
                            </Typography>
                        </Stack>
                    </motion.div>
                </Stack>

            </Stack>
        </Box>
)
}

export default ArcadeAcademyHighlights;