import {Box, Grid2, Typography} from "@mui/material";

import download2 from "../../assets/images/download_2.webp";

const mainContainer = {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '0rem',
    paddingTop: '1rem',
    paddingBottom: '0',
    color: 'white'
}

const DesktopApp = () => {
    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{fontWeight: '600', fontStyle: 'italic'}}>Coming Soon!</Typography>
            <Grid2 spacing={{md: 4, lg: 15, xl: 15}} container sx={{paddingY: '4rem', width: {xs: '90vw', sm: '580px', md: '860px', lg: '1150px', xl: '1500px'}}}>
                <Grid2 size={{xs: 12, md: 7}} sx={{paddingY: '2rem'}}>
                    <Typography variant='h4' sx={{fontWeight: '600'}}>Get THE ARCADE ACADEMY</Typography>
                    <Typography variant='h4' sx={{fontWeight: '400', fontSize: '2rem'}}>Windows and Mac OS!</Typography>
                    <Typography vaiant='body2' sx={{marginY: '2rem', fontSize: '0.85rem'}}>
                        "Transform learning into adventure with The Arcade Academy's interactive games for students, parents and schools. Anytime and Anywhere!"
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12, md: 5}} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginX: {xs: 'auto', md: 0}}}>
                    <Box component='img' src={download2} alt={'Desktop Applications'} sx={{height: {xs: 'auto', sm: '350px', md: '300px', lg: '350px', xl: '450px'}, width: {xs: '100%', sm: '450px', md: '100%', lg: '100%', xl: '100%'}}} />
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default DesktopApp;