import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/home";
import CONSTANTS from "./constants";
import Navbar from "./sections/navbar/navbar";
import {Box} from "@mui/material";
import Footer from "./sections/footer/footer";
import Games from "./pages/games";
import Downloads from "./pages/downloads";
import Game from "./pages/game";
import Blogs from "./pages/blogs";
import SignIn from "./pages/sign_in";
import SignUp from "./pages/sign_up";
import RouteCheck from "./components/helper/routeCheck";

const App = () => {
    const location = useLocation();
    const pathname = location.pathname;

    return (
        <>
            <RouteCheck />
            {!pathname.startsWith("/game/") &&
                <>
                    <Navbar />
                    <Box sx={{width: '100%', height: {xs: '56px', md: '64px'}}} />
                </>
            }
            <Routes>
                <Route path={`${CONSTANTS.URLS.HOME}`} element={<Home />} />
                <Route path={`${CONSTANTS.URLS.GAMES}`} element={<Games />} />
                <Route path={`${CONSTANTS.URLS.DOWNLOADS}`} element={<Downloads />} />
                <Route path={`${CONSTANTS.URLS.GAME}`} element={<Game /> } />
                <Route path={`${CONSTANTS.URLS.BLOGS}`} element={<Blogs /> } />
                <Route path={`${CONSTANTS.URLS.SIGN_IN}`} element={<SignIn /> } />
                <Route path={`${CONSTANTS.URLS.SIGN_UP}`} element={<SignUp /> } />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
