import UpcomingBestGames from "../sections/games/upcomingBestGames";
import UpcomingVRARGames from "../sections/games/upcomingVRARGames";

const Games = () => {
    window.scrollTo(0, 0);

    return(
        <>
            <UpcomingBestGames />
            <UpcomingVRARGames />
        </>
    )
}

export default Games;