import React from 'react';
import ReactDOM from 'react-dom/client';
import './styleSheets/index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {persistor, store} from './store/store';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import {ThemeProvider} from "@mui/material";
import theme from "./config/themeConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename={"/"}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);