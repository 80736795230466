import {Box, Button, Stack} from "@mui/material";
import {MdExitToApp} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const mainContainer = {
    minHeight: '100vh',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
}

const buttonStyle = {
    border: '2px solid #2b3c4e',
    backgroundColor: 'white',
    color: '#2b3c4e',
    paddingY: '0.5rem',
    fontWeight: '600',
    borderRadius: "0.25rem",
    paddingX: '1rem',
    transition: '0.25s',
    '&:hover': {
        backgroundColor: '#2b3c4e',
        color: 'white'
    }
}

const Header = ({game}) => {
    const navigate = useNavigate();

    return(
        <Box sx={{...mainContainer, background: `url(${game.img}) center/cover no-repeat`}}>
            {/*<Box component='img' src={game.img} alt={game.title} sx={{height: '100%', width: '100%'}}/>*/}
            <Stack sx={{position: 'absolute', rowGap: '0.5rem', bottom: '1rem', left: '1rem'}}>
                <Button sx={buttonStyle}>Play Free Demo!</Button>
            </Stack>
            <Stack sx={{position: 'absolute', rowGap: '0.5rem', top: '1rem', left: '1rem'}}>
                <Button endIcon={<MdExitToApp />} sx={buttonStyle} onClick={()=> navigate("/games")}>Back to Games</Button>
            </Stack>
        </Box>
    )
}

export default Header;