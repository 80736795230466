import {Box, Grid2, Stack, Typography} from "@mui/material";
import {FaApple} from "react-icons/fa";
import {grey} from "@mui/material/colors";

import download1 from "../../assets/images/download_1.webp";
import google_play from "../../assets/icons/google_play.svg";

const mainContainer = {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '0rem',
    paddingTop: '3rem',
    paddingBottom: '0',
    color: 'white'
}

const buttonStyles = {
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '1rem',
    backgroundColor: 'black',
    padding: '0.25rem 1rem',
    border: `2px solid ${grey[500]}`,
    borderRadius: '0.5rem',
    minWidth: '190px',
    maxWidth: '190px',
    cursor: 'pointer',
    transition: '0.25s',
    '&:hover': {
        backgroundColor: '#2f2f2f',
    }
}

const MobileApps = () => {
    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{paddingTop: '2rem', fontWeight: '600', paddingX: '1rem', textAlign: 'center'}}>Multiplatform Access!</Typography>
            <Typography vaiant='body2' sx={{fontSize: '0.85rem', paddingX: '1rem', textAlign: 'center'}}>
                "The Arcade Academy accessible for students, parents and schools anytime, anywhere!"
            </Typography>
            <Grid2 spacing={{md: 4, lg: 15, xl: 15}} container sx={{paddingY: '4rem', width: {xs: '90vw', sm: '580px', md: '860px', lg: '1150px', xl: '1500px'}}}>
                <Grid2 size={{xs: 12, sm: 12, md: 7}} sx={{paddingY: '2rem'}}>
                    <Typography variant='h4' sx={{fontWeight: '600'}}>Get THE ARCADE ACADEMY</Typography>
                    <Typography variant='h4' sx={{fontWeight: '400', fontSize: '2rem'}}>App Now!</Typography>
                    <Typography vaiant='body2' sx={{marginY: '2rem', fontSize: '0.85rem'}}>
                        "Transform learning into adventure with The Arcade Academy's interactive games for students, parents and schools. Anytime and Anywhere!"
                    </Typography>
                    <Stack sx={{alignItems: 'start', justifyContent: 'center', rowGap: '1rem'}}>
                        <Stack direction='row' sx={buttonStyles}>
                            <FaApple size={50} style={{color: grey[100]}}/>
                            <Stack>
                                <Typography variant='body2'>Available on the</Typography>
                                <Typography variant='h6' sx={{fontWeight: 'bold'}}>App Store</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction='row' sx={buttonStyles}>
                            <Box component='img' src={google_play} alt='Google Play' sx={{height: '50px', width: '50px'}}/>
                            <Stack>
                                <Typography variant='body2'>GET IT ON</Typography>
                                <Typography variant='h6' sx={{fontWeight: 'bold'}}>Google Play</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 5}} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginX: {xs: 'auto', md: 0}}}>
                    <Box component='img' src={download1} alt={'Mobile Applications'} sx={{height: {xs: 'auto', sm: '350px', md: '350px', lg: '400px', xl: '450px'}, width: {xs: '100%', sm: '375px', md: '100%', lg: '100%', xl: '80%'}}} />
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default MobileApps;