import {Box, Button, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {yellow} from "@mui/material/colors";
import headerImage from "../../assets/images/header_logo.png";
import headerLogoLg from "../../assets/images/header_logo_lg.png";
import {MdLogout} from "react-icons/md";
import CONSTANTS from "../../constants";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";

const mainContainer = {
    minHeight: {xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)'},
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
}

const headerMain = {
    background: 'linear-gradient(90deg, rgba(44,68,92,1) 0%, rgba(82,166,254,1) 100%)',
    minHeight: 'fit-content',
    height: 'fit-content',
    width: '100%',
    alignItems: {xs: 'center', md: 'start'},
    justifyContent: 'space-between',
    flexDirection: {xs: 'column', md: 'row'},
    columnGap: {md: '2rem', lg: '4rem'}
}

const buttonsHeader = {
    paddingY: '0.75rem',
    backgroundColor: yellow[600],
    color: '#062e42',
    fontWeight: '600',
    width: '260px',
    "&:hover": {
        backgroundColor: yellow[700],
    }
}

const backgroundWhiteContainer = {
    height: {xs: 'auto'},
    maxHeight: {xs: '300px', sm: '350px', md: '400px', lg: '450px'},
    borderRadius: '50%',
    backgroundColor: 'white',
    minWidth: {xs: '300px', sm: '350px', md: '400px', lg: '450px'},
    maxWidth: {xs: '300px', sm: '350px', md: '400px', lg: '450px'},
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const navButtons = {
    backgroundColor: yellow[600],
    color: '#062e42',
    textTransform: 'capitalize',
    fontWeight: '600',
    width: '140px',
    "&:hover": {
        backgroundColor: yellow[700],
    }
}

const Header = () => {
    const theme = useTheme();
    const lgScreen = useMediaQuery(theme.breakpoints.up('md'));
    const mdScreen = useMediaQuery(theme.breakpoints.only('md'));
    const xlScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const navigate = useNavigate();

    const routeToWeb = (route) => {
        if (route === 'sub') {
            window.open(CONSTANTS.EXTERNAL_WEB_URLS.SUBSCRIBE, "_black");
        } else {
            window.open(CONSTANTS.EXTERNAL_WEB_URLS.SURVEY, "_blank");
        }
    }

    const routeToPage = (route) => {
        navigate(route);
    }

    return(
        <Box id={'home-header'} sx={mainContainer}>
            <Box sx={{height: {xs: '2vh', sm: '4vh',md: '10vh'}}} />
            <Stack sx={headerMain}>
                <Stack sx={{padding: '3rem 1rem 1rem 1rem', textAlign: {xs: 'center', md: 'start'}}}>
                    <motion.div
                        initial={{x: -500, opacity: 0}} // move the text off-screen to the left
                        animate={{x: 0, opacity: 1}} // move the text back to its original position
                        transition={{duration: 1, ease: 'easeOut'}} // animation settings
                    >
                        {!lgScreen && <Stack direction={{xs: 'column', sm: 'row'}} sx={{
                            columnGap: '1rem',
                            rowGap: '1rem',
                            marginBottom: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button onClick={() => routeToWeb('sub')} sx={navButtons} endIcon={<MdLogout/>}>
                                Subscribe
                            </Button>
                            <Button sx={navButtons} onClick={() => routeToWeb('sur')} endIcon={<MdLogout/>}>
                                Survey
                            </Button>
                        </Stack>}
                        <Typography variant='h4' sx={{
                            color: 'white',
                            fontWeight: '600',
                            fontSize: {xs: '1.75rem', md: '2rem', lg: '2.25rem'}
                        }}>
                            Learn through Play with
                        </Typography>
                        <Typography variant='h4' sx={{
                            marginY: '0.5rem',
                            color: yellow[600],
                            fontWeight: '600',
                            fontSize: {xs: '1.85rem', md: '2.25rem', lg: '2.75rem'},
                            textDecoration: 'underline',
                            textDecorationColor: yellow[600],
                            textDecorationThickness: '4px'
                        }}>
                            The Arcade Academy
                        </Typography>
                        <Typography variant='body2' sx={{
                            fontSize: {xs: '0.75rem', md: '1rem'},
                            marginY: 'auto',
                            color: 'white',
                            maxWidth: {xs: '300px', sm: '600px', md: '700px'},
                            alignSelf: 'center',
                            marginX: {xs: 'auto', md: '0'},
                        }}>
                            The arcade academy is revolutionizing learning through interactive games. Designed for
                            students,
                            parents and schools, our platform use AR, 3D and 2D games to make education fun, memorable
                            and
                            accessible on any device, mobile, PC or web.
                        </Typography>
                    </motion.div>
                </Stack>
                <motion.div
                    initial={{x: 500, opacity: 0}} // move the text off-screen to the left
                    animate={{x: 0, opacity: 1}} // move the text back to its original position
                    transition={{duration: 1, ease: 'easeOut'}} // animation settings
                    style={{height: (mdScreen ? '350px' : xlScreen ? '375px' : 'auto'), maxHeight: (mdScreen ? '350px' : xlScreen ? '375px' : 'auto')}}
                >
                    {!lgScreen && <Box sx={backgroundWhiteContainer}>
                        <Box component='img' sx={{
                            height: {xs: 'auto'},
                            maxHeight: {xs: '300px', sm: '350px', md: '400px', lg: '450px'},
                            width: '75%',
                            objectFit: 'contain'
                        }} src={headerImage} alt='Arcade Game'/>
                    </Box>}
                    {lgScreen && <Box component='img' sx={{
                        height: {md: '350px', lg: '375px'},
                        maxHeight: {md: '350px', lg: '375px'},
                        width: {md: '350px', lg: '375px'},
                        objectFit: 'cover'
                    }}
                         src={headerLogoLg} alt='Arcade Game'/>}
                </motion.div>
            </Stack>
            <Stack sx={{
                padding: '1rem',
                columnGap: '1rem',
                marginTop: 'auto',
                rowGap: '1rem',
                alignItems: {xs: 'center', md: 'start'}
            }} direction={{xs: 'column', md: 'row'}}>
                <Button sx={buttonsHeader} onClick={()=> routeToPage("/sign_in")}>
                    GET STARTED!
                </Button>
                <Button sx={buttonsHeader} onClick={()=> routeToPage("/sign_up")}>
                    I ALREADY HAVE AN ACCOUNT?
                </Button>
            </Stack>
        </Box>
    )
}

export default Header;