import {Box, Stack, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import {motion} from "framer-motion";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setGameInfo} from "../../../store/slices/gameSlice";

const cardStyle = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '275px',
    textAlign: 'start',
    rowGap: '0rem',
    border: '2px solid #2b3c4e',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    transition: '0.25s',
    height: '100%',
    '&:hover': {
        scale: '1.025',
        boxShadow: '0 0 32px 0 rgba(0,0,0,0.25)'
    }
}

const GameCard = ({cardData}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeToGameDetailPage = () => {
        dispatch(setGameInfo(cardData));
        navigate(`/game/${cardData.id}`)
    }

    return (
        <motion.div
            initial={{y: -150, opacity: 0}} // move the text off-screen to the left
            whileInView={{y: 0, opacity: 1}} // move the text back to its original position
            transition={{duration: cardData.duration, ease: 'easeOut'}} // animation settings
            style={{display: 'flex', flex: 1}}
        >
            <Stack sx={cardStyle} onClick={()=> routeToGameDetailPage()}>
                <Stack sx={{height: '250px', alignItems: 'end', justifyContent: 'top', backgroundImage: `url(${cardData.img})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem', margin: '0.75rem 1rem'}}>
                        <Typography variant='body2' sx={{
                            fontSize: '0.75rem',
                            padding: '0.125rem 0.5rem',
                            backgroundColor: yellow[700],
                            border: '2px solid #2b3c4e',
                            borderTopLeftRadius: '50%',
                            borderBottomRightRadius: '50%'
                        }}>
                            {cardData.technology[0]}
                        </Typography>
                        <Typography variant='body2' sx={{
                            fontSize: '0.75rem',
                            padding: '0.125rem 0.5rem',
                            backgroundColor: yellow[700],
                            border: '2px solid #2b3c4e',
                            borderTopLeftRadius: '1rem',
                            borderBottomRightRadius: '1rem'
                        }}>
                            {cardData.condition}
                        </Typography>
                        <Typography variant='body2' sx={{
                            fontSize: '0.75rem',
                            padding: '0.125rem 0.5rem',
                            backgroundColor: yellow[700],
                            border: '2px solid #2b3c4e',
                            borderTopLeftRadius: '50%',
                            borderBottomRightRadius: '50%',
                        }}>
                            {cardData.technology[1]}
                        </Typography>
                    </Stack>
                </Stack>
                <Box sx={{backgroundColor: yellow[700], paddingY: '1rem', paddingX: '0.5rem', flex: 1}}>
                    <Typography variant='h6' sx={{fontWeight: '600'}}>{cardData.title}</Typography>
                    <Typography variant='body2' sx={{fontSize: '0.7rem'}}>{cardData.description}</Typography>
                </Box>
            </Stack>
        </motion.div>
    )
}

export default GameCard;