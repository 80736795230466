import {createSlice} from '@reduxjs/toolkit'

export const gameSlice = createSlice({
    name: 'game',
    initialState: {
        id: -1,
        title: "",
        platform: [],
        condition: "",
        description: "",
        img: null
    },
    reducers: {
        setGameInfo: (state, action) => {
            state = action.payload;
            return state;
        },
    },
})

export const { setGameInfo } = gameSlice.actions

export default gameSlice.reducer