import game1 from "../assets/images/games/game_1.webp";
import game3 from "../assets/images/games/game_3.webp";
import game4 from "../assets/images/games/game_4.webp";
import game5 from "../assets/images/games/game_5.webp";
import game6 from "../assets/images/games/game_6.webp";
import game7 from "../assets/images/games/game_7.webp";
import game8 from "../assets/images/games/game_8.webp";
import game9 from "../assets/images/games/game_9.webp";
import game10 from "../assets/images/games/game_10.webp";
import game11 from "../assets/images/games/game_11.webp";

const gamesData = [
    {
        id: 1,
        title: "Archery 3D",
        description: "Archery 3D is an archery game! Grab your bow and arrows and attack!",
        img: game10,
        age: "8-12",
        type: "Action / Simulation",
        subject: "Archery, Hand-eye coordination",
        technology: ["3D", "Mobile"]
    },

    {
        id: 2,
        title: "Cube Slum",
        description: "Play cube slum online in the browser or challenge your friend over it.",
        img: game11,
        age: "6-12",
        type: "Puzzle / Strategy",
        subject: "Spatial awareness, Problem-solving",
        technology: ["2D", "Mobile"]
    },

    {
        id: 4,
        title: "Tile Match 3D",
        description: "Tile Match 3D is a perfect game for kids aged 3 and up. It allows practice with sorting by color in an engaging way.",
        img: game5,
        age: "4-8",
        type: "Puzzle",
        subject: "Sorting, Color recognition",
        technology: ["3D", "Mobile"]
    },

    {
        id: 5,
        title: "Drums & Music Hall",
        description: "Drums & Music Hall Game is an interactive learning game that will teach you how to play some instruments and how to compose music from scratch.",
        img: game3,
        age: "6-12",
        type: "Music / Interactive Learning",
        subject: "Music, Instruments",
        technology: ["3D", "VR"]
    },

    {
        id: 6,
        title: "Candy Match 3D",
        description: "Candy Match 3D is a puzzle game that is designed for children who are 3 years old and above, and its objective is to match groups of tiles by attribute in order to clear the board.",
        img: game4,
        age: "4-8",
        type: "Puzzle",
        subject: "Pattern recognition, Logic",
        technology: ["3D", "Mobile"]
    },

    {
        id: 8,
        title: "Score Hero",
        description: "Score Hero it’s very easy and fun to play: You swipe repeatedly to shoot a soccer ball into a goal. After the first level, enemies appear who will try to kick the ball from you and cause you to lose",
        img: game1,
        age: "8-12",
        type: "Sports / Strategy",
        subject: "Soccer, Hand-eye coordination",
        technology: ["3D", "Mobile"]
    },

    {
        id: 9,
        title: "Block Wars",
        description: "This game is perfect for kids aged 8 and up, and it’s all about getting the most blocks in your color and winning the round. Block Wars is a fun way to practice ratio and probability skills.",
        img: game8,
        age: "8-12",
        type: "Strategy / Action",
        subject: "Ratio, Probability",
        technology: ["3D", "Mobile"]
    },

    {
        id: 10,
        title: "Color Run",
        description: "Get ready for an exciting new game for kids aged 6 and up! In this game, you’ll have to use your strategy and timing skills to guide multiple characters along a rail without colliding with each other. Enjoy while learning with Paint The Path!",
        img: game9,
        age: "6-10",
        type: "Action / Strategy",
        subject: "Strategy, Timing skills",
        technology: ["2D", "Mobile"]
    },

    {
        id: 11,
        title: "Hide and Seek",
        description: "The good old classic hide & seek. Play either as a seeker or as a hider and build your shelters from cars or office desks, hide in the water, in the hay pile, in the cornfield, in the boss' office and most importantly, push others in the seeker's vision field. Try to be kind though.",
        img: game6,
        age: "6-12",
        type: "Action / Simulation",
        subject: "Strategy, Reflexes",
        technology: ["3D", "Mobile"]
    },

    {
        id: 12,
        title: "Car Racing",
        description: "Sick of endless racing games with third person perspective? \"Car Racing\" might be the game you are looking for. You drive your car in cockpit view through the endless traffic and realistic environment. Go as fast as possible, overtake traffic cars, earn coins and buy new cars. Eventually, become the king of the global leaderboards.",
        img: game7,
        age: "8-12",
        type: "Racing",
        subject: "Driving, Hand-eye coordination",
        technology: ["3D", "Mobile"]
    },
];


export default gamesData;