import {Box, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import MeetTeamCard from "../../components/home/meetTeam/meetTeamCard";
import {Swiper, SwiperSlide} from 'swiper/react';

import momi_hero from "../../assets/images/team/momi_hero.png";
import balli_hero from "../../assets/images/team/muhammad_bilal.png";
import rashid from "../../assets/images/team/rashid.jpg";

import 'swiper/css';

const mainContainer = {
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '1rem',
    paddingBottom: {xs: '1rem', md: '5rem'},
    rowGap: '2rem'
}

const cardsData = [
    {
        name: "Mumshad Jawad",
        position: "Founder & CEO",
        description: "Leads the Arcade Academy with a passion for blending education and gaming",
        img: momi_hero
    },

    {
        name: "Muhammad Bilal",
        position: "Founder & CTO",
        description: "Ensure our platform stays at the cutting edge of the technology, overseeing the development and integration of our games across all devices",
        img: balli_hero
    },

    {
        name: "Rashid Nawaz",
        position: "Product Head",
        description: "Shapes the education content and design of our games, ensuring they are both engaging and effective",
        img: rashid
    }
]

const MeetTeam = () => {
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const smScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const mdScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return(
        <Box sx={mainContainer}>
            <Typography variant='h3' sx={{fontWeight: '800', color: '#062e42', textTransform: 'capitalize'}}>
                MEET OUT TEAM
            </Typography>
            <Stack direction='row' sx={{alignItems: 'start', justifyContent: 'center', width: {xs: '320px', sm: '580px', md: '850px', lg: '1100px'}}}>
                <Swiper
                    spaceBetween={(xsScreen ? 5 : smScreen ? 10 : mdScreen ? 15 : 20)}
                    slidesPerView={(xsScreen ? 1 : smScreen ? 2 : mdScreen ? 3 : 3)}
                    centeredSlides={false}
                    autoplay={true}
                    initialSlide={1}
                >
                    {
                        cardsData.map((cardData, index) => (
                            <SwiperSlide key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <MeetTeamCard cardData={cardData}/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Stack>
        </Box>
    )
}

export default MeetTeam;