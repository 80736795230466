import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {MdLogout, MdMenu} from "react-icons/md";
import {yellow} from "@mui/material/colors";
import "../../styleSheets/navbar.css";
import {useEffect, useState} from "react";
import logo from "../../assets/images/arcade_academy_logo.png";
import headerImage from "../../assets/images/header_logo.png";
import CONSTANTS from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {setNavigation} from "../../store/slices/navigationSlice";

const navButtons = {
    backgroundColor: yellow[600],
    color: '#062e42',
    textTransform: 'capitalize',
    fontWeight: '600',
    width: '140px',
    "&:hover": {
        backgroundColor: yellow[700],
    }
}

const tabStyle = {
    color: "#062e42",
    fontSize: '0.95rem',
    fontWeight: '600',
    textTransform: 'capitalize',
    padding: '0.25rem 1rem',
    cursor: 'pointer',
    transition: '0.25s',
    borderRadius: '0.125rem',
    marginY: '0.5rem',
}

const tabs = [
    {
        title: "About",
        route: CONSTANTS.URLS.HOME
    },

    {
        title: "Games",
        route: CONSTANTS.URLS.GAMES
    },

    {
        title: "Downloads",
        route: CONSTANTS.URLS.DOWNLOADS
    },

    {
        title: "Blog",
        route: CONSTANTS.URLS.BLOGS
    },

    // {
    //     title: "Media",
    //     route: "/media"
    // },

    {
        title: "Contact",
        route: CONSTANTS.URLS.HOME
    },
]

const Navbar = () => {
    const dispatch = useDispatch();
    const route = useSelector(state => state.navigation.route);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (route && route !== "") {
            if (route === "/") {
                setSelectedTab(0);
            } else if (route === "/games") {
                setSelectedTab(1);
            } else if (route === "/downloads") {
                setSelectedTab(2);
            } else if (route === "/blogs") {
                setSelectedTab(3);
            }
        }
    }, [route]);

    const handleSelectedTab = (index, route) => {
        if (index === 4) {
            setSelectedTab(0);
        } else {
            setSelectedTab(index);
        }
        dispatch(setNavigation(route));

        if (index === 0) {
            setTimeout(() => {
                const aboutSection = document.getElementById('home-header');
                if (aboutSection) {
                    aboutSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }

        if (index === 4) {
            setTimeout(() => {
                const contactSection = document.getElementById('contact');
                if (contactSection) {
                    contactSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }

    const routeToWeb = (route) => {
        if (route === 'sub') {
            window.open(CONSTANTS.EXTERNAL_WEB_URLS.SUBSCRIBE, "_black");
        } else {
            window.open(CONSTANTS.EXTERNAL_WEB_URLS.SURVEY, "_blank");
        }
    }

    return(
        <>
            <AppBar>
                <Toolbar sx={{backgroundColor: 'white'}}>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <Box component='img' src={logo} alt={"Arcade Academy"} sx={{height: '50px', cursor: 'pointer'}} onClick={()=> handleSelectedTab(0, '/')}/>
                        {!smScreen && <Stack direction='row'>
                            {
                                tabs.map((tab, index) => (
                                    <Typography onClick={()=> handleSelectedTab(index, tab.route)} variant='h6' key={index} sx={{...tabStyle, backgroundColor: selectedTab === index ? yellow[600] : 'transparent'}} className={"navbar-tabs"}>
                                        {tab.title}
                                    </Typography>
                                ))
                            }
                        </Stack>}
                        <Stack direction='row' columnGap={'1rem'}>
                            {!smScreen && <Stack direction='row' sx={{columnGap: '1rem'}}>
                                <Button onClick={()=> routeToWeb('sub')} sx={navButtons} endIcon={<MdLogout />}>
                                    Subscribe
                                </Button>
                                <Button sx={navButtons} onClick={()=> routeToWeb('sur')} endIcon={<MdLogout />} >
                                    Survey
                                </Button>
                            </Stack>}
                            {smScreen &&
                                <Tooltip title={'Open Menu'}>
                                    <IconButton onClick={()=> setOpenDrawer(true)}>
                                        <MdMenu />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor={'right'}>
                <Box sx={{ width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} role="presentation" onClick={() => setOpenDrawer(false)}>
                    <Box component='img' src={headerImage} alt={'The Arcade Academy'} sx={{height: '150px', width: '150px', paddingY: '2rem'}} />
                    <List sx={{width: '100%'}}>
                        {tabs.map((tab,index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={()=> handleSelectedTab(index, tab.route)}>
                                    <ListItemText>{tab.title}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default Navbar;