import {Box, Grid2, Typography} from "@mui/material";
import {FaPersonCircleQuestion} from "react-icons/fa6";
import {TbMessageMinus} from "react-icons/tb";
import {IoNewspaperOutline} from "react-icons/io5";
import {BsCpu} from "react-icons/bs";
import GameInfoCard from "../../components/game/gameInfoCard";
import {yellow} from "@mui/material/colors";

const mainContainer = {
    height: 'fit-content',
    width: {xs: 'calc(90vw - 2rem)', sm: 'calc(550px -2rem)', md: 'calc(850px - 2rem)', lg: 'calc(1150px - 2rem)', xl: 'calc(1500px - 2rem)'},
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '1rem',
    paddingY: '4rem',
    color: '#293c50',
    marginX: 'auto',
    textAlign: 'center',
}

const cardsData = [
    {
        icon: <FaPersonCircleQuestion size={75}/>,
        title: "Age"
    },

    {
        icon: <IoNewspaperOutline size={75}/>,
        title: "Subject"
    },

    {
        icon: <TbMessageMinus size={75}/>,
        title: "Type"
    },

    {
        icon: <BsCpu size={75}/>,
        title: "Technology"
    }
]


const GameDetails = ({game}) => {

    return(
        <Box sx={mainContainer}>
            <Typography variant='h3' sx={{color: yellow[600], fontWeight: '600', marginBottom: '2rem'}}>{game.title}</Typography>
            <Typography variant='h4' sx={{fontWeight: '600'}}>Game Description</Typography>
            <Typography vaiant='body2' sx={{fontSize: '1rem', marginY: '2rem'}}>
                {game.description}
            </Typography>
            <Grid2 container spacing={{xs: 2, md: 8}}>
                {
                    cardsData.map((cardData, index) => (
                        <GameInfoCard cardData={cardData} key={index} gameData={game}/>
                    ))
                }
            </Grid2>
        </Box>
    )
}

export default GameDetails;