import {Avatar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {blue} from "@mui/material/colors";
import {motion} from "framer-motion";

const MeetTeamCard = ({cardData}) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <motion.div
            style={{
                width: '275px',
                maxWidth: '225px',
                height: (md ? '350px' : lg ? '325px' : '325px'),
                margin: '1rem',
                borderRadius: '0.75rem',
                padding: '2.5rem 1.75rem',
                boxShadow: '0 0 16px 0 rgba(0,0,0,0.25)',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}
            initial={{y: -150, opacity: 0}} // move the text off-screen to the left
            whileInView={{y: 0, opacity: 1}} // move the text back to its original position
            transition={{duration: 1, ease: 'easeOut'}} // animation settings
        >
            <Avatar src={cardData.img} sx={{border: `3px solid ${blue[600]}`, height: '125px', width: '125px', marginBottom: '1rem'}}/>
            <Typography variant='h5' sx={{fontWeight: '600', fontSize: {md: '1.40rem', lg: '1.5rem'}}}>{cardData.name}</Typography>
            <Typography variant='h6' sx={{fontWeight: '600'}}>{cardData.position}</Typography>
            <Typography variant='body2' sx={{marginTop: '0.75rem'}}>{cardData.description}</Typography>
        </motion.div>
    )
}

export default MeetTeamCard;