
const STAGING = "DEVELOPMENT";

const ENV = {
    DEVELOPMENT: {
        WEB_URL: 'http://localhost:3000',
    },
    STAGING: {
        WEB_URL: 'http://localhost:3000',
    },
    PRODUCTION: {
        WEB_URL: "https://thearcadeacademy.com"
    }
}

const BASE_URL = `${STAGING === "DEVELOPMENT" ? ENV.DEVELOPMENT.WEB_URL : ENV.PRODUCTION.WEB_URL}`

const CONSTANTS = {
    URLS: {
        HOME: "/",
        GAMES: "/games",
        DOWNLOADS: '/downloads',
        GAME: '/game/:id',
        BLOGS: "/blogs",
        SIGN_IN: "/sign_in",
        SIGN_UP: "/sign_up"
    },

    EXTERNAL_WEB_URLS: {
        SUBSCRIBE: "https://app.rapidforms.co/p/9f7b48",
        SURVEY: "https://app.rapidforms.co/p/4aec12"
    }
}

export default CONSTANTS;