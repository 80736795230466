import {Box, Stack, Typography} from "@mui/material";
import {FaFacebookF, FaGooglePlusG, FaLinkedin, FaTwitter} from "react-icons/fa";

const mainContainer = {
    height: 'fit-content',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#363636',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '1rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    rowGap: '1rem'
}

const Footer = () => {
    return(
        <Box sx={mainContainer}>
            <Typography variant='h4' sx={{fontWeight: '600', color: 'white', textAlign: 'center'}}>The Arcade Academy</Typography>
            <Typography variant='body2' sx={{color: 'white', maxWidth: {xs: '90%', sm: '550px', md: '700px'}, textAlign: 'center'}}>
                "The Arcade Academy transforms learning into an adventure with engaging VR, 3D and 2D games. Designed for
                students, parents and schools, our platform makes education fun and accessible anytime and anywhere.
            </Typography>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem', color: 'white'}}>
                <FaFacebookF size={22}/>
                <FaTwitter size={25}/>
                <FaGooglePlusG size={35}/>
                <FaLinkedin size={25}/>
            </Stack>
        </Box>
    )
}

export default Footer;