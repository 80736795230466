import {Box, Grid2, Stack, Typography} from "@mui/material";
import "../../styleSheets/gameCardDetail.css"

const cardStyle = {
    padding: '2rem 0',
    rowGap: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.5)',
    borderRadius: '0.5rem',
    textAlign: 'center',
    width: '200px',
    height: '100px',
    overflow: 'hidden',
};

const GameInfoCard = ({cardData, gameData}) => {
    return(
        <Grid2 size={{xs: 12, sm: 6, md: 3}} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box className={'scene'}>
                <Box className={'cube'}>
                    <Stack className={'cube__face cube__face--front'} sx={cardStyle}>
                        {cardData.icon}
                        <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                            {cardData.title}
                        </Typography>
                    </Stack>
                    <Stack className={'cube__face cube__face--left'} sx={cardStyle}>
                        <Typography sx={{ fontWeight: 'bold', color: 'white', marginX: '2rem', textWrap: 'break-word' }} variant="h5">
                            {cardData.title === 'Age' ? gameData.age : cardData.title === 'Type' ? gameData.type : cardData.title === 'Subject' ? gameData.subject : cardData.title === 'Technology' ? gameData.technology : ''}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Grid2>
    )
}

export default GameInfoCard;