import {Box, Grid2, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import contactBG from "../../assets/images/contact_bg.png";
import {yellow} from "@mui/material/colors";
import {motion} from "framer-motion";

const mainContainer = {
    minHeight: '400px',
    height: 'fit-content',
    width: {xs: 'calc(100% - 2rem)', md: '100%'},
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: {xs: '1rem', md: 0},
    paddingTop: '3rem',
    paddingBottom: '5rem',
    rowGap: '2rem',
    position: 'relative',
    overflow: 'hidden'
}

const ContactInfo = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box sx={mainContainer} id={'contact'}>
            {!sm &&
                <Box component='img' src={contactBG} alt={"Contact BG"} sx={{height: '400px', width: '300px', alignSelf: 'start', position: 'absolute', left: 0}}/>
            }
            <Grid2 container sx={{width: '100%', flex: 1}}>
                <Grid2 size={{xs: 0, md: 4}}/>
                <Grid2 size={{xs: 12, sm: 6, md: 4}} sx={{
                    display: 'flex',
                    alignItems: {xs: 'start', md: 'center'},
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Stack sx={{
                        width: {xs: '100%', md: 'fit-content'},
                        textAlign: {xs: 'center', sm: 'start', md: 'start'},
                        alignItems: {xs: 'center', sm: 'start', md: 's'},
                        justifyContent: 'center'
                    }}>
                        <Typography variant='h4' sx={{fontWeight: '600'}}>CONTACT US</Typography>
                        <Typography variant='body2' sx={{color: yellow[600], textAlign: 'start'}}>
                            Engage, Educate, Entertain!
                        </Typography>
                    </Stack>
                </Grid2>
                <Grid2 size={{xs: 12, sm: 6, md: 4}}
                       sx={{display: 'flex', justifyContent: {xs: 'center', sm: 'end'}}}>
                    <motion.div
                        initial={{x: 150, opacity: 0}} // move the text off-screen to the left
                        whileInView={{x: 0, opacity: 1}} // move the text back to its original position
                        transition={{duration: 1, ease: 'easeOut'}} // animation settings
                    >
                        <Stack sx={{
                            alignItems: {xs: 'center', sm: 'start'},
                            justifyContent: 'space-between',
                            height: '100%',
                            width: 'fit-content',
                            paddingX: '1rem'
                        }}>
                            <Stack sx={{textAlign: {xs: 'center', sm: 'start'}}}>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>COMPANY NUMBER</Typography>
                                <Typography variant='body2'>
                                    +971-521861400
                                </Typography>
                            </Stack>

                            <Stack sx={{textAlign: {xs: 'center', sm: 'start'}}}>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>EMAIL</Typography>
                                <Typography variant='body2' sx={{textAlign: 'start'}}>
                                    info@thearcadeacademy.com
                                </Typography>
                            </Stack>

                            <Stack sx={{textAlign: {xs: 'center', sm: 'start'}}}>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>SITE</Typography>
                                <Typography variant='body2' sx={{textAlign: 'start'}}>
                                    Office 15, Al-Khabaisi, 29a street, Dubai
                                </Typography>
                            </Stack>
                        </Stack>
                    </motion.div>
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default ContactInfo;