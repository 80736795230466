import Header from "../sections/home/header";
import ArcadeAcademyHighlights from "../sections/home/arcadeAcademyHighlights";
import FeaturedGames from "../sections/home/featuredGames";
import WhatIsArcadeAcademy from "../sections/home/whatIsArcadeAcademy";
import MeetTeam from "../sections/home/meetTeam";
import ContactInfo from "../sections/home/contactInfo";

const Home = () => {
    return(
        <>
            <Header />
            <ArcadeAcademyHighlights />
            <FeaturedGames />
            <WhatIsArcadeAcademy />
            <MeetTeam />
            <ContactInfo />
        </>
    )
}

export default Home;